import React, { useState, useEffect } from "react"
import cx from "classnames"
import { useStaticQuery, graphql } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => {
  const { page } = useStaticQuery(graphql`
    {
      page: markdownRemark(frontmatter: { slug: { eq: "/" } }) {
        frontmatter {
          title
        }
        html
      }
    }
  `)
  const year = new Date().getFullYear()
  const [show, setShow] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setShow(true)
    }, 500)
  }, [])

  return (
    <Layout>
      <SEO title={page.frontmatter.title} />
      <section className="home">
        <div className="home__front">
          <div className="home__front__main">
            <div
              className={cx("home__content", { show })}
              dangerouslySetInnerHTML={{ __html: page.html }}
            />
            <div className="home__contact">
              <p className="home__contact__question">
                Do you have a project in mind?
              </p>
              <OutboundLink
                href="mailto:info@behind.design"
                className={cx("home__contact__action", { show })}
              >
                <strong>info@behind.design</strong>
              </OutboundLink>
            </div>
          </div>
          <p className="copyright">
            <small>{year} © Behind.Design</small>
          </p>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
